import React from "react";
import "./footer.css";
import { Link } from "react-scroll";
import logo_footer from "../../img/logo_footer.webp";
import { NavLink } from "react-router-dom";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { FiMapPin } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div id="footer">
      <div onClick={scrollToTop} className="scroll-to-top">
        <MdKeyboardDoubleArrowUp />
      </div>

      <div className="footer">
        <div className="all_footer">
          <div className="left">
            <NavLink to="" onClick={scrollToTop} className="logo">
              <img src={logo_footer} alt="logo" />
            </NavLink>
          </div>

          <div className="center">
            <h2>
              {t("footer_1")} <span>{t("footer_2")}</span>
            </h2>

            <p>
              {t("footer_3")} <br /> {t("footer_4")}
            </p>

            <div className="email_phone">
              <a
                href="https://api.whatsapp.com/send?phone=97433779585"
                target="_blank"
                rel="noreferrer"
              >
                <FaPhoneAlt /> / <FaWhatsapp /> : 33779585
              </a>

              <a
                href="mailto:info@mrautoqa.com"
                target="_blank"
                rel="noreferrer"
              >
                <MdOutlineEmail /> : Info@mrautoqa.com
              </a>

              <b>
                <FiMapPin /> : {t("footer_5")}
              </b>
              <b>{t("footer_6")}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
