import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Header from "./header/Header";
import Copyrights from "../../components/copyrights/Copyrights";
import BookCar from "./bookCar/BookCar";
import About from "./about/About";
import PlanTripNow from "./planTripNow/PlanTripNow";
import Footer from "../../components/footer/Footer";

const Home = ({ direction, setDirection }) => {
  return (
    <>
      <Navbar direction={direction} setDirection={setDirection} />
      <Header />
      <BookCar />
      <About />
      <PlanTripNow />

      <Footer />
      <Copyrights />
    </>
  );
};

export default Home;
