import React, { useState } from "react";
import "../bookCar.css";
import { toast } from "react-toastify";
import ShowSelect from "./showSelect/ShowSelect";
import { useTranslation } from "react-i18next";

const SelectCarType = () => {
  const [selectedCarType, setSelectedCarType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [day, setDay] = useState(0);
  const [dayDiffInDays, setDayDiffInDays] = useState(0);
  const { t } = useTranslation();

  const [hideSelect, setHideSelect] = useState(false);

  const handleCarTypeChange = (value) => {
    setSelectedCarType(value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (startDate !== "" && endDate !== "") {
      const startDateObj = new Date(startDate);
      startDateObj.setHours(0, 0, 0, 0);

      const endDateObj = new Date(endDate);
      endDateObj.setHours(0, 0, 0, 0);

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const diffInDays = calculateDateDifference(startDate, endDate);

      if (
        startDateObj < currentDate ||
        endDateObj < currentDate ||
        endDateObj < startDateObj
      ) {
        toast.warning(t("select_car_toast_2"));
        return;
      }

      if (diffInDays == 0) {
        setDay(1);
      } else if (diffInDays == 1) {
        setDay(2);
      } else if (diffInDays == 2) {
        setDay(3);
      } else {
        setDay(3);
      }

      setDayDiffInDays(diffInDays + 1);
      setHideSelect(true);
    } else {
      toast.warning(t("select_car_toast_3"));
    }
  };

  const calculateDateDifference = (start, end) => {
    const startDateObj = new Date(start);
    const endDateObj = new Date(end);
    const timeDiff = Math.abs(endDateObj - startDateObj);
    const diffInDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return diffInDays;
  };

  return (
    <>
      {hideSelect && (
        <ShowSelect
          day={day}
          select={selectedCarType}
          setHide={setHideSelect}
          startDate={startDate}
          endDate={endDate}
          dayDiffInDays={dayDiffInDays}
        />
      )}

      <div className="full_select_car">
        <div className="title">
          <h2>
            {t("select_car_1")} <br />
            {t("select_car_2")}
          </h2>
          <span></span>
        </div>

        <form className="select_car_type" onSubmit={handleSubmit}>
          <div className="top">
            <div
              onClick={() => handleCarTypeChange("inside_qatar")}
              className="selectgcc"
              style={{
                backgroundColor:
                  selectedCarType === "inside_qatar" ? "var(--bg_2)" : "",
                color: selectedCarType === "inside_qatar" ? "var(--ff)" : "",
              }}
            >
              {t("select_car_3")}
            </div>

            <div
              onClick={() => handleCarTypeChange("gcc_countries")}
              className="selectgcc"
              style={{
                backgroundColor:
                  selectedCarType === "gcc_countries" ? "var(--bg_2)" : "",
                color: selectedCarType === "gcc_countries" ? "var(--ff)" : "",
              }}
            >
              {t("select_car_4")}
            </div>
          </div>

          <div className="bottom">
            <div
              className={`select ${
                selectedCarType == "" ? "hide_selected" : "show_selected"
              }`}
            >
              <label htmlFor="Date of">{t("select_car_10")}</label>
              <input
                type="date"
                name="date_of"
                id="date_of"
                disabled={
                  selectedCarType == "" ||
                  (selectedCarType == "inside_qatar" && false)
                }
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>

            <div
              className={`select ${
                selectedCarType == "" ? "hide_selected" : "show_selected"
              }`}
            >
              <label htmlFor="Date to">{t("select_car_11")}</label>
              <input
                type="date"
                name="date_to"
                id="date_to"
                disabled={
                  selectedCarType == "" ||
                  (selectedCarType == "inside_qatar" && false)
                }
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>

            <input
              type="submit"
              className={`submit ${
                selectedCarType == "" ? "hide_selected" : "show_selected"
              }`}
              value={t("select_car_12")}
              disabled={selectedCarType == "" && true}
              style={{ opacity: selectedCarType == "" ? 0.3 : 1 }}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default SelectCarType;
