import React from "react";
import "./about.css";
import Counter from "../../../components/counter/Counter";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <div id="about">
      <div className="span">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="about">
        <div className="title_div">
          <div className="title">
            <h2>
              {t("about_1")} <br />
              {t("about_2")}
            </h2>
          </div>
        </div>
        <div className="title_div">
          <div className="title engine">
            <div className="start_engine">
              <h3>
                <span>{t("about_3")}</span> {t("about_4")}
              </h3>

              <p>
                {t("about_5")}
                <br />
                {t("about_6")}
              </p>

              <div className="start_engine_count">
                <div className="count">
                  <b>
                    +<Counter endNumber={20} speed={100} />
                  </b>
                  <p>{t("about_7")}</p>
                </div>
                <div className="count">
                  <b>
                    +<Counter endNumber={90} speed={50} />
                  </b>
                  <p>{t("about_8")}</p>
                </div>
                <div className="count">
                  <b>
                    +<Counter endNumber={10} speed={100} />
                  </b>
                  <p>{t("about_9")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
