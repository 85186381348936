import React, { useEffect, useState } from "react";
import "./navbar.css";
import logo from "../../img/logo.webp";
import { Link } from "react-scroll";
import Packages from "../../page/home/packages/Packages";
import Offer from "../../page/home/offer/Offer";
import { useTranslation } from "react-i18next";

const Navbar = ({ direction, setDirection }) => {
  const [isChecked, setChecked] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [hidePackages, setHidePackages] = useState(false);
  const [hideOffer, setHideOffer] = useState(false);
  const { i18n, t } = useTranslation();

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const handleToggle = () => {
    setChecked(!isChecked);
    if (isChecked) {
      setScrolling(false);
    } else {
      setScrolling(true);
    }
  };

  const links = [
    { to: "header", name: t("home") },
    { to: "book_car", name: t("booking") },
    { to: "about", name: t("about") },
    { onclick: "offers", to: "offers", name: t("offers") },
    { onclick: "packages", to: "book_car", name: t("packages") },
    { to: "planTripNow", name: t("services") },
    { to: "footer", name: t("contact") },
  ];

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isChecked]);

  const handleOpenPackages = () => {
    setHidePackages(true);
  };

  const handleOpenOffer = () => {
    setHideOffer(true);
  };

  const ChangeEN = () => {
    i18n.changeLanguage("en");
    setDirection("ltr");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
    setDirection("rtl");
  };

  return (
    <>
      {hidePackages && <Packages setHidePackages={setHidePackages} />}

      {hideOffer && <Offer setHideOffer={setHideOffer} />}

      <nav className={scrolling ? "scrolled" : ""}>
        <input
          type="checkbox"
          id="nav-toggle"
          checked={isChecked}
          onChange={handleToggle}
        />

        <Link
          to="header"
          spy={true}
          smooth={true}
          offset={-150}
          duration={500}
          className="logo"
        >
          <img src={logo} alt="logo" />
        </Link>

        <ul className="links" onClick={handleToggle}>
          {links.map((item, i) =>
            item.onclick == "offers" ? (
              <button key={i} onClick={handleOpenOffer}>
                {item.name}
              </button>
            ) : item.onclick == "packages" ? (
              <button key={i} onClick={handleOpenPackages}>
                {item.name}
              </button>
            ) : (
              <li key={i}>
                <Link
                  onClick={handleToggle}
                  to={item.to}
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                >
                  {item.name}
                </Link>
              </li>
            )
          )}
        </ul>

        {direction == "ltr" ? (
          <div className="ChangeLang" onClick={ChangeAR}>
            AR
          </div>
        ) : (
          <div className="ChangeLang" onClick={ChangeEN}>
            EN
          </div>
        )}

        <label htmlFor="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>
    </>
  );
};

export default Navbar;
