import React, { useEffect, useState } from "react";
import "./showSelect.css";
import toyota_land_cruiser_2023 from "../../../../../img/toyota_land_cruiser_2023.webp";
import { IoCloseSharp } from "react-icons/io5";
import ShowSelectTerms from "./ShowSelectTerms";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ShowSelect = ({
  startDate,
  endDate,
  dayDiffInDays,
  day,
  select,
  setHide,
}) => {
  const [hideTerms, setHideTerms] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();

  // console.log("day " + day);
  // console.log("select " + select);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const show_select = [
    { id: 1, price_1: "999", price_2: "499" },
    { id: 2, price_1: "750", price_2: "375" },
    { id: 3, price_1: "499", price_2: "249" },
    { id: 4, price_1: "999", price_2: "499" },
  ];

  let select_title;

  let select_qatar;

  if (select === "inside_qatar") {
    select_title = t("show_select_1");
    select_qatar = t("show_select_2");
  } else if (select === "gcc_countries") {
    select_title = t("show_select_3");
    select_qatar = t("show_select_4");

    day = 4;
  }

  // console.log("package: " + select_title);
  // console.log("start Date: " + startDate);
  // console.log("end Date: " + endDate);
  // console.log("day: " + dayDiffInDays);

  let selectedData = show_select.find((item) => item.id === day);

  const handleClose = () => {
    setHide(false);
  };

  const handleHideTerms = () => {
    setHideTerms(true);
  };

  const handleHref = () => {
    const showSelectElement = document.querySelector(
      ".Package_full_ul ul:nth-child(1)"
    );

    let showInnerText = "";

    if (showSelectElement) {
      showInnerText = showSelectElement.innerText;
    }

    const textLines = showInnerText.split("\n");

    const formattedText = textLines.join("%0A");

    if (isChecked) {
      window.open(
        `https://wa.me/97433779585/?text=HI MR-AUTO %0A %0A package: ${select_title}%0A start Date: ${startDate}%0A end Date: ${endDate}%0A number of days: ${dayDiffInDays} day %0A %0A ${formattedText}`,
        "_blank",
        "noreferrer"
      );
    } else {
      toast.warning(t("show_select_5"));
    }
  };

  // console.log(selectedData);

  return (
    <>
      {hideTerms && (
        <ShowSelectTerms select={select} setHideTerms={setHideTerms} />
      )}
      <div id="show_select">
        <IoCloseSharp className="IoCloseSharp" onClick={handleClose} />

        <img
          src={toyota_land_cruiser_2023}
          alt="toyota_land_cruiser_2023"
          className="toyota_land_cruiser_2023"
        />
        <div className="show_select">
          {selectedData && (
            <>
              <h2>
                {t("show_select_6")} <br />
                {select_title}
              </h2>
              <div className="selected_data_all">
                <div className="selected_data data_1">
                  <b>
                    {t("show_select_7")} <br /> {t("show_select_8")}
                  </b>
                  <p>
                    {select === "gcc_countries" ? 3 : day}{" "}
                    <span>{t("show_select_9")}</span>
                  </p>
                </div>

                <div className="selected_data data_2">
                  <b>
                    {t("show_select_10")} <br /> {t("show_select_11")}
                  </b>
                  <p>
                    {selectedData.price_1} <span>{t("show_select_12")}</span>
                  </p>
                </div>

                <div className="selected_data data_3">
                  <b>
                    {t("show_select_13")} <br /> {t("show_select_11")}
                  </b>
                  <p>
                    {selectedData.price_2} <span>{t("show_select_12")}</span>
                  </p>
                </div>
              </div>

              <div className="Package_full_ul">
                <ul>
                  <li>{t("show_select_14")}</li>
                  <li>{t("show_select_15")}</li>
                  <li>{t("show_select_16")}</li>
                  <li>{t("show_select_17")}</li>
                  <li>{t("show_select_18")}</li>
                </ul>

                <br />

                <h4>{t("show_select_19")}</h4>
                <ul>
                  <li>{t("show_select_20")}</li>
                  <li>{t("show_select_21")}</li>
                  {select === "inside_qatar" && <li>{select_qatar}</li>}
                </ul>
              </div>

              <div className="center">
                <label>
                  <input
                    type="checkbox"
                    name="example_checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />

                  <p className="handleHideTerms">{t("show_select_22")} </p>
                </label>
                <p className="handleHideTerms" onClick={handleHideTerms}>
                  {t("show_select_23")}
                </p>
              </div>

              <button
                className="terms_book_now"
                onClick={handleHref}
                style={{ opacity: !isChecked ? 0.3 : 1 }}
              >
                {t("show_select_24")}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ShowSelect;
