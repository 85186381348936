import React from "react";
import "./showSelect.css";
import toyota_land_cruiser_2023 from "../../../../../img/toyota_land_cruiser_2023.webp";
import { IoCloseSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const ShowSelectTerms = ({ select, setHideTerms }) => {
  const { t } = useTranslation();
  // console.log("select " + select);

  let make_accident_inside_qatar;
  let select_qatar;

  if (select === "inside_qatar") {
    make_accident_inside_qatar = t("show_select_terms_1");
  } else if (select === "gcc_countries") {
    select_qatar = t("show_select_terms_2");
    make_accident_inside_qatar = t("show_select_terms_3");
  }

  const handleClose = () => {
    setHideTerms(false);
  };

  return (
    <div id="show_select" className="show_select_terms">
      <IoCloseSharp className="IoCloseSharp" onClick={handleClose} />

      <img
        src={toyota_land_cruiser_2023}
        alt="toyota_land_cruiser_2023"
        className="toyota_land_cruiser_2023"
      />

      <div className="show_select">
        <div className="Package_full_ul">
          <h4>{t("show_select_terms_4")}</h4>
          <ul>
            {select === "gcc_countries" && <li>{select_qatar}</li>}

            <li>{t("show_select_terms_5")}</li>
            <li>{t("show_select_terms_6")}</li>
            <li>{t("show_select_terms_7")}</li>
            <li>{make_accident_inside_qatar}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ShowSelectTerms;
