import React from "react";
import "./planTripNow.css";
import quick_easy_1 from "../../../img/quick_easy_1.webp";
import quick_easy_2 from "../../../img/quick_easy_2.webp";
import quick_easy_3 from "../../../img/quick_easy_3.webp";
import quick_easy_arrow from "../../../img/quick_easy_arrow.webp";
import { useTranslation } from "react-i18next";

const PlanTripNow = () => {
  const { t } = useTranslation();

  return (
    <>
      <div id="planTripNow">
        <div className="planTripNow">
          <div className="title_div">
            <div className="title">
              <p>{t("services_1")}</p>
              <h2>{t("services_2")}</h2>
              <p>{t("services_3")}</p>
            </div>
          </div>

          <div className="div_quick_easy">
            <div className="quick_easy">
              <div className="img_quick_easy">
                <img src={quick_easy_1} alt="quick_easy_1" />
              </div>
              <h2>{t("services_4")}</h2>
              <p>{t("services_5")}</p>
              <p>{t("services_6")}</p>
              <p>{t("services_7")}</p>
            </div>

            <img
              src={quick_easy_arrow}
              alt="quick_easy_arrow"
              className="quick_easy_arrow_phone left"
            />

            <div className="quick_easy arrow">
              <div className="img_quick_easy">
                <img src={quick_easy_2} alt="quick_easy_2" />
              </div>
              <h2>
                <img
                  src={quick_easy_arrow}
                  alt="quick_easy_arrow"
                  className="quick_easy_arrow_computer left"
                />
                {t("services_8")}
                <img
                  src={quick_easy_arrow}
                  alt="quick_easy_arrow"
                  className="quick_easy_arrow_computer right"
                />
              </h2>
              <p>{t("services_9")}</p>
              <p>{t("services_10")}</p>
              <p>{t("services_11")}</p>
            </div>

            <img
              src={quick_easy_arrow}
              alt="quick_easy_arrow"
              className="quick_easy_arrow_phone right"
            />

            <div className="quick_easy">
              <div className="img_quick_easy">
                <img src={quick_easy_3} alt="quick_easy_3" />
              </div>
              <h2>{t("services_12")}</h2>
              <p>{t("services_13")}</p>
              <p>{t("services_14")}</p>
              <p>{t("services_15")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanTripNow;
