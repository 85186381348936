import React from "react";
import "./bookCar.css";
import SelectCarType from "./selectCarType/SelectCarType";

const BookCar = () => {
  return (
    <div id="book_car">
      <SelectCarType />
    </div>
  );
};

export default BookCar;
