export const arabicDataAr = {
  home: "الصفحة الرئيسية",
  about: "معلومات عنا",
  offers: "عروض",
  packages: "باقات",
  services: "خدمات",
  contact: "تواصل معنا",
  /////header/////
  header_1: "خطط لرحلتك الآن!",
  header_2: "وفّر",
  header_3: "بشكل كبير",
  header_4: "مع خدمة تأجير سيارات اللاند كروزر فقط",
  header_5: "احجز الآن",
  /////select_car/////
  select_car_1: "تخصيص",
  select_car_2: "باقتك الخاصة",
  select_car_3: "داخل قطر",
  select_car_4: "خارج قطر",
  select_car_5: "اختيار موقع",
  select_car_6: "الدوحة",
  select_car_7: "الريان",
  select_car_8: "الوكرة",
  select_car_9: "الخور",
  select_car_10: "من تاريخ",
  select_car_11: "الي تاريخ",
  select_car_12: "تم",
  // ***
  select_car_toast_1: "حقل الموقع فارغ",
  select_car_toast_2: "مر وقت التاريخ",
  select_car_toast_3: "حقل التاريخ فارغ",

  /////about/////
  about_1: "معلومات عن",
  about_2: "الشركة",
  about_3: "من",
  about_4: "نحن",
  about_5:
    "مرحبًا بك في [مستر أوتو]، خدمة تأجير السيارات الموثوقة في قطر وعبر منطقة الخليج. نفخر بتقديم حلول تأجير السيارات المتميزة لتلبية احتياجاتك في المواصلات.",
  about_6:
    "من خلال خبرتنا قد أثبتنا أنفسنا كشركة تأجير سيارات موثوقة ومتميزة في خدمة العملاء.",
  about_7: "أنواع السيارات",
  about_8: "نقاط تأجير",
  about_9: "مراكز الإصلاح",
  /////services/////
  services_1: "نحن نقدم مجموعة واسعة من خدمات تأجير السيارات",
  services_2: "الخدمات",
  services_3: "تشمل خدماتنا",
  services_4: "تأجير قصير الأجل:",
  services_5: "استأجر سيارة ليوم واحد",
  services_6: "أو 3 أيام أو أسبوع،",
  services_7: " حسب احتياجاتك.",
  services_8: "خدمة عملاء على مدار الساعة:",
  services_9: "فريق خدمة العملاء المتخصص متاح على مدار الساعة",
  services_10: "لمساعدتك في أي استفسارات",
  services_11: "أو حجوزات أو حالات الطوارئ.",
  services_12: "تأجير طويل الأجل:",
  services_13: "إذا كنت بحاجة إلى سيارة لفترة طويلة،",
  services_14: "فإننا نقدم خيارات تأجير طويل الأجل",
  services_15: "بأسعار تنافسية.",
  /////footer/////
  footer_1: "اتصل",
  footer_2: "بنا",
  footer_3: "استمتع بالراحة والموثوقية",
  footer_4: " والأسعار التنافسية مع مستر أوتو.",
  footer_5: "مبنى 20، شارع 950، منطقة 27 - الدوحة، قطر",
  footer_6: "صندوق بريد 40124",
  ////////ShowSelectTerms/////////
  show_select_terms_1:
    "إذا تعرض العميل لحادث داخل قطر، سيتم تحصيل رسوم لأيام الإصلاح المطلوبة للسيارة (نفس سعر إيجار السيارة اليومي).",
  show_select_terms_2: "يجب أن يتم الحجز قبل 48 ساعة",
  show_select_terms_3:
    "إذا تعرض العميل لحادث خارج قطر، سيتم تحصيل رسوم لأيام الإصلاح المطلوبة للسيارة (نفس سعر إيجار السيارة اليومي).",
  show_select_terms_4: "الشروط والأحكام:",
  show_select_terms_5:
    "مواعيد السائق: 8 ساعات + 4 ساعات مجانية = الحد الأقصى 12 ساعة.",
  show_select_terms_6:
    "أكثر من 12 ساعه سيتم فرض رسوم قدرها 99 ريال قطري لكل ساعة بعد 8 ساعات.",
  show_select_terms_7: "يتحمل العميل تكاليف الوقود والصيانة خلال فترة الحجز.",
  /////////ShowSelect////////////
  show_select_1: "الباقات داخل قطر:",
  show_select_2: "يمكن إجراء الحجز في أي وقت",
  show_select_3: "دول مجلس التعاون الخليجي:",
  show_select_4: "يجب أن يتم الحجز قبل 48 ساعة",
  show_select_5: "يجب عليك الموافقة على الاحكام والشروط",
  show_select_6: "باقات",
  show_select_7: "عدد",
  show_select_8: "الايام.",
  show_select_9: "يوم",
  show_select_10: "",
  show_select_11: "رسوم اليوم",
  show_select_12: "ريال قطري",
  show_select_13: "سائق شخصي",
  show_select_14: "تويوتا لاند كروزر (2016 إلى 2021)",
  show_select_15: "اوتوماتيك",
  show_select_16: "رفاهية",
  show_select_17: "7 أشخاص",
  show_select_18: "5 أمتعة كبيرة",
  show_select_19: "مزايا",
  show_select_20: "كم غير محدود",
  show_select_21: "24 خط ساخن لخدمة العملاء",
  show_select_22: "الشروط والأحكام",
  show_select_23: "المزيد...",
  show_select_24: "احجز الآن",
  /////////Offer//////////
  Offer_1: "داخل",
  Offer_2: "قطر",
  Offer_3: "عدد",
  Offer_4: "الايام.",
  Offer_5: "يوم",
  Offer_6: "واحدة مجانية",
  Offer_7: "",
  Offer_8: "رسوم اليوم",
  Offer_9: "ريال قطري",
  Offer_10: "يوم واحد",
  Offer_11: "تويوتا لاند كروزر (2016 إلى 2021)",
  Offer_12: "اوتوماتيك",
  Offer_13: "رفاهية",
  Offer_14: "الناس",
  Offer_15: "أمتعة كبيرة",
  Offer_16: "مزايا",
  Offer_17: "كم غير محدود",
  Offer_18: "خدمة عملاء الخط الساخن",
  Offer_19: "يمكن إجراء الحجز في أي وقت",
  Offer_20: "الشروط والأحكام",
  Offer_21: "مواعيد السائق 8 ساعات + 4 ساعات مجانية = 12 ساعة كحد أقصى",
  Offer_22: "أكثر من 12 ساعة سيتم احتساب 99 ريال قطري لكل ساعة بعد 8 ساعات",
  Offer_23: "الوقود والصيانة ستكون من قبل العميل خلال فترة الحجز",
  Offer_24:
    "في حال تعرض العميل لحادث داخل قطر سيتم احتساب رسوم الأيام المطلوبة لإصلاح السيارة (نفس سعر إيجار السيارة يومياً)",
  Offer_25: "خارج",
  Offer_26: "قطر",
  Offer_27: "عدد",
  Offer_28: "الايام.",
  Offer_29: "يوم",
  Offer_30: "واحدة مجانية",
  Offer_31: "",
  Offer_32: "رسوم اليوم",
  Offer_33: "ريال قطري",
  Offer_34: "يوم واحد",
  Offer_35: "تويوتا لاند كروزر (2016 إلى 2021)",
  Offer_36: "اوتوماتيك",
  Offer_37: "رفاهية",
  Offer_38: "الناس",
  Offer_39: "أمتعة كبيرة",
  Offer_40: "مزايا",
  Offer_41: "كم غير محدود",
  Offer_42: "خدمة عملاء الخط الساخن",
  Offer_43: "الشروط والأحكام",
  Offer_44: "يجب أن يتم الحجز قبل 48 ساعة",
  Offer_45: "مواعيد السائق 8 ساعات + 4 ساعات مجانية = 12 ساعة كحد أقصى",
  Offer_46: "أكثر من 12 ساعة سيتم احتساب 99 ريال قطري لكل ساعة بعد 8 ساعات",
  Offer_47: "الوقود والصيانة ستكون من قبل العميل خلال فترة الحجز",
  Offer_48:
    "في حال تعرض العميل لحادث خارج قطر سيتم احتساب رسوم الأيام المطلوبة لإصلاح السيارة (نفس سعر إيجار السيارة يومياً)",
  Offer_49: "احجز الآن",
  //////Packages//////////
  Packages_1: "داخل قطر",
  Packages_2: "دول مجلس التعاون الخليجي:",
  Packages_3: "باقات",
  Packages_4: "عدد",
  Packages_5: "الايام.",
  Packages_6: "يوم",
  Packages_7: "",
  Packages_8: "رسوم اليوم",
  Packages_9: "ريال قطري",
  Packages_10: "يوم واحد",
  Packages_11: "يوم واحد",
  Packages_12: "سائق شخصي",
  Packages_13: "رسوم اليوم",
  Packages_14: "ريال قطري",
  Packages_15: "يوم واحد",
  Packages_16: "يوم واحد",
  Packages_17: "تويوتا لاند كروزر (2016 إلى 2021)",
  Packages_18: "اوتوماتيك",
  Packages_19: "رفاهية",
  Packages_20: "الناس",
  Packages_21: "أمتعة كبيرة",
  Packages_22: "مزايا",
  Packages_23: "كم غير محدود",
  Packages_24: "خدمة عملاء الخط الساخن",
  Packages_25: "يمكن إجراء الحجز في أي وقت",
  Packages_26: "الشروط والأحكام",
  Packages_27: "مواعيد السائق 8 ساعات + 4 ساعات مجانية = 12 ساعة كحد أقصى",
  Packages_28: "أكثر من 12 ساعة سيتم احتساب 99 ريال قطري لكل ساعة بعد 8 ساعات",
  Packages_29: "الوقود والصيانة ستكون من قبل العميل خلال فترة الحجز",
  Packages_30:
    "في حال تعرض العميل لحادث داخل قطر سيتم احتساب رسوم الأيام المطلوبة لإصلاح السيارة (نفس سعر إيجار السيارة يومياً)",
  Packages_31: "باقات",
  Packages_32: "عدد",
  Packages_33: "الايام.",
  Packages_34: "يوم",
  Packages_35: "",
  Packages_36: "رسوم اليوم",
  Packages_37: "ريال قطري",
  Packages_38: "يوم واحد",
  Packages_39: "سائق شخصي",
  Packages_40: "رسوم اليوم",
  Packages_41: "ريال قطري",
  Packages_42: "يوم واحد",
  Packages_43: "تويوتا لاند كروزر (2016 إلى 2021)",
  Packages_44: "اوتوماتيك",
  Packages_45: "رفاهية",
  Packages_46: "الناس",
  Packages_47: "أمتعة كبيرة",
  Packages_48: "مزايا",
  Packages_49: "كم غير محدود",
  Packages_50: "خدمة عملاء الخط الساخن",
  Packages_51: "الشروط والأحكام",
  Packages_52: "يجب أن يتم الحجز قبل 48 ساعة",
  Packages_53: "مواعيد السائق 8 ساعات + 4 ساعات مجانية = 12 ساعة كحد أقصى",
  Packages_54: "أكثر من 12 ساعة سيتم احتساب 99 ريال قطري لكل ساعة بعد 8 ساعات",
  Packages_55: "الوقود والصيانة ستكون من قبل العميل خلال فترة الحجز",
  Packages_56:
    "في حال تعرض العميل لحادث خارج قطر سيتم احتساب رسوم الأيام المطلوبة لإصلاح السيارة (نفس سعر إيجار السيارة يومياً)",
  Packages_57: "احجز الآن",
  ////////SaveWith////////
  SaveWith_1: "يحفظ",
  SaveWith_2: "مع",
  SaveWith_3: "تأجير السيارات لدينا!",
  SaveWith_4: "أعلى المطارات. الموردين المحليين. دعم 24/7.",
  SaveWith_5: "احجز الآن",
};

export default arabicDataAr;
