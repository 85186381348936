export const englishDataEn = {
  home: "home",
  about: "about",
  offers: "offers",
  packages: "packages",
  services: "services",
  contact: "contact",
  /////header/////
  header_1: "PLAN YOUR TRIP NOW!",
  header_2: "SAVE BIG",
  header_3: "WITH BIG",
  header_4: "ONLY LAND CRUISER RENTAL",
  header_5: "Book Now",
  /////select_car/////
  select_car_1: "CUSTOM",
  select_car_2: "YOUR PACKAGE",
  select_car_3: "Qatar",
  select_car_4: "GCC",
  select_car_5: "Choose location",
  select_car_6: "Doha",
  select_car_7: "Al Rayyan",
  select_car_8: "Al Wakra",
  select_car_9: "Al Khor",
  select_car_10: "Date of",
  select_car_11: "Date to",
  select_car_12: "done",
  // ***
  select_car_toast_1: "The location field is empty",
  select_car_toast_2: "History time has passed",
  select_car_toast_3: "The date field is empty",

  /////about/////
  about_1: "About",
  about_2: "Company",
  about_3: "Who",
  about_4: "We Are",
  about_5:
    "Welcome to [Mr Auto], your trusted car rental service in Qatar and across the Gulf region. We pride ourselves on providing top-notch car rental solutions to meet your transportation needs.",
  about_6:
    "experience in the industry, we have established ourselves as a reliable and customer-centric car rental company.",
  about_7: "Car Types",
  about_8: "Rental Outlets",
  about_9: "Repair Shop",
  /////services/////
  services_1: "We offer a wide range of car rental services",
  services_2: "Services",
  services_3: "Our services include",
  services_4: "Short-Term Rentals",
  services_5: "Rent a car for a day,",
  services_6: "or 3 days or a week,",
  services_7: "depending on your needs.",
  services_8: "24/7 Customer Support",
  services_9:
    "Our dedicated customer support team is available round the clock to",
  services_10: "assist you with any queries,",
  services_11: "reservations, or emergency needs.",
  services_12: "Long-Term Rentals",
  services_13: "If you require a car for an extended period,",
  services_14: "we offer long-term rental",
  services_15: "options at competitive rates.",
  /////footer/////
  footer_1: "Contact",
  footer_2: "Us",
  footer_3: "Experience convenience, reliability,",
  footer_4: "and affordability with Mr Auto.",
  footer_5: "Building 20, Street 950, Zone 27 - Doha,Qatar",
  footer_6: "PO Box 40124",
  ////////ShowSelectTerms/////////
  show_select_terms_1:
    "If the client make an accident inside Qatar, he will be charges the fees of days requested to repair the car ( the same price of rent car daily)",
  show_select_terms_2: "Booking should be done before 48 hours",
  show_select_terms_3:
    "If the client make an accident outside Qatar, he will be charges the fees of days requested to repair the car ( the same price of rent car daily)",
  show_select_terms_4: "Terms and conditions",
  show_select_terms_5:
    "Chauffeur timings 8 hours + 4 hours free = 12 hours maximum",
  show_select_terms_6:
    "More than 12 hours will be charged 99 Qr per each hour after 8 hours",
  show_select_terms_7:
    "Fuel and maintenance will be by client during the period of booking",
  /////////ShowSelect////////////
  show_select_1: "(Local) qatar",
  show_select_2: "Booking can be done at anytime",
  show_select_3: "gcc countries",
  show_select_4: "Booking should be done before 48 hours",
  show_select_5: "You must agree to the Terms and conditions",
  show_select_6: "Packages",
  show_select_7: "No. of",
  show_select_8: "days.",
  show_select_9: "Day",
  show_select_10: "Vehicle per",
  show_select_11: "day charges",
  show_select_12: "Qr",
  show_select_13: "Chauffeur per",
  show_select_14: "Toyota land cruiser ( 2016 to 2021)",
  show_select_15: "Automatic",
  show_select_16: "Luxury",
  show_select_17: "7 people",
  show_select_18: "5 large baggage",
  show_select_19: "Advantages",
  show_select_20: "Unlimited km",
  show_select_21: "24 hotline customer service",
  show_select_22: "Terms and conditions",
  show_select_23: "more...",
  show_select_24: "Book Now",
  /////////Offer//////////
  Offer_1: "Local",
  Offer_2: "qatar",
  Offer_3: "No. of",
  Offer_4: "days.",
  Offer_5: "Day",
  Offer_6: "One Free",
  Offer_7: "Vehicle per",
  Offer_8: "day charges",
  Offer_9: "Qr",
  Offer_10: "One Day",
  Offer_11: "Toyota land cruiser ( 2016 to 2021)",
  Offer_12: "Automatic",
  Offer_13: "Luxury",
  Offer_14: "people",
  Offer_15: "large baggage",
  Offer_16: "Advantages",
  Offer_17: "Unlimited km",
  Offer_18: "hotline customer service",
  Offer_19: "Booking can be done at anytime",
  Offer_20: "Terms and conditions",
  Offer_21: "Chauffeur timings 8 hours + 4 hours free = 12 hours maximum",
  Offer_22:
    "More than 12 hours will be charged 99 Qr per each hour after 8 hours",
  Offer_23:
    "Fuel and maintenance will be by client during the period of booking",
  Offer_24:
    "If the client make an accident inside Qatar, he will be charges the fees of days requested to repair the car ( the same price of rent car daily)",
  Offer_25: "Gcc",
  Offer_26: "qatar",
  Offer_27: "No. of",
  Offer_28: "days.",
  Offer_29: "Day",
  Offer_30: "One Free",
  Offer_31: "Vehicle per",
  Offer_32: "day charges",
  Offer_33: "Qr",
  Offer_34: "One Day",
  Offer_35: "Toyota land cruiser ( 2016 to 2021)",
  Offer_36: "Automatic",
  Offer_37: "Luxury",
  Offer_38: "people",
  Offer_39: "large baggage",
  Offer_40: "Advantages",
  Offer_41: "Unlimited km",
  Offer_42: "hotline customer service",
  Offer_43: "Terms and conditions",
  Offer_44: "Booking should be done before 48 hours",
  Offer_45: "Chauffeur timings 8 hours + 4 hours free = 12 hours maximum",
  Offer_46:
    "More than 12 hours will be charged 99 Qr per each hour after 8 hours",
  Offer_47:
    "Fuel and maintenance will be by client during the period of booking",
  Offer_48:
    "If the client make an accident outside Qatar, he will be charges the fees of days requested to repair the car ( the same price of rent car daily)",
  Offer_49: "Book Now",
  //////Packages//////////
  Packages_1: "(Local) qatar",
  Packages_2: "gcc countries",
  Packages_3: "Packages",
  Packages_4: "No. of",
  Packages_5: "days.",
  Packages_6: "Day",
  Packages_7: "Vehicle per",
  Packages_8: "day charges",
  Packages_9: "Qr",
  Packages_10: "One Day",
  Packages_11: "One Day",
  Packages_12: "Chauffeur per",
  Packages_13: "day charges",
  Packages_14: "Qr",
  Packages_15: "One Day",
  Packages_16: "One Day",
  Packages_17: "Toyota land cruiser ( 2016 to 2021)",
  Packages_18: "Automatic",
  Packages_19: "Luxury",
  Packages_20: "people",
  Packages_21: "large baggage",
  Packages_22: "Advantages",
  Packages_23: "Unlimited km",
  Packages_24: "hotline customer service",
  Packages_25: "Booking can be done at anytime",
  Packages_26: "Terms and conditions",
  Packages_27: "Chauffeur timings 8 hours + 4 hours free = 12 hours maximum",
  Packages_28:
    "More than 12 hours will be charged 99 Qr per each hour after 8 hours",
  Packages_29:
    "Fuel and maintenance will be by client during the period of booking",
  Packages_30:
    "If the client make an accident inside Qatar, he will be charges the fees of days requested to repair the car ( the same price of rent car daily)",
  Packages_31: "Packages",
  Packages_32: "No. of",
  Packages_33: "days.",
  Packages_34: "Day",
  Packages_35: "Vehicle per",
  Packages_36: "day charges",
  Packages_37: "Qr",
  Packages_38: "One Day",
  Packages_39: "Chauffeur per",
  Packages_40: "day charges",
  Packages_41: "Qr",
  Packages_42: "One Day",
  Packages_43: "Toyota land cruiser ( 2016 to 2021)",
  Packages_44: "Automatic",
  Packages_45: "Luxury",
  Packages_46: "people",
  Packages_47: "large baggage",
  Packages_48: "Advantages",
  Packages_49: "Unlimited km",
  Packages_50: "hotline customer service",
  Packages_51: "Terms and conditions",
  Packages_52: "Booking should be done before 48 hours",
  Packages_53: "Chauffeur timings 8 hours + 4 hours free = 12 hours maximum",
  Packages_54:
    "More than 12 hours will be charged 99 Qr per each hour after 8 hours",
  Packages_55:
    "Fuel and maintenance will be by client during the period of booking",
  Packages_56:
    "If the client make an accident outside Qatar, he will be charges the fees of days requested to repair the car ( the same price of rent car daily)",
  Packages_57: "Book Now",
  ////////SaveWith////////
  SaveWith_1: "save",
  SaveWith_2: "with",
  SaveWith_3: "our car rental!",
  SaveWith_4: "top Airports. Local Suppliers. 24/7 Support.",
  SaveWith_5: "Book Now",
};

export default englishDataEn;
