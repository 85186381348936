import React, { useState } from "react";
import NotFound from "./page/notFound/NotFound";
import { Route, Routes } from "react-router-dom";
import Home from "./page/home/Home";

const App = () => {
  const [direction, setDirection] = useState("ltr");

  return (
    <div style={{ direction: direction }} className={`direction_${direction}`}>
      <Routes>
        <Route
          path="/"
          element={<Home direction={direction} setDirection={setDirection} />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
