import React, { useState } from "react";
import toyota_land_cruiser_2023 from "../../../img/toyota_land_cruiser_2023.webp";
import { IoCloseSharp } from "react-icons/io5";
import "./packages.css";
import { useTranslation } from "react-i18next";

const Packages = ({ setHidePackages }) => {
  const [hideLocal, setHideLocal] = useState(false);
  const [hideGcc, setHideGcc] = useState(false);
  const { t } = useTranslation();

  const show_select = [
    { id: 1, price_1: "999", price_2: "499" },
    { id: 2, price_1: "750", price_2: "375" },
    { id: 3, price_1: "499", price_2: "249" },
  ];

  const handleClose = () => {
    setHidePackages(false);
  };

  const handleShowPackageLocal = () => {
    setHideLocal(true);
    setHideGcc(false);
  };

  const handleShowPackageGcc = () => {
    setHideGcc(true);
    setHideLocal(false);
  };

  const handleHref = () => {
    window.open("https://api.whatsapp.com/send?phone=97433779585", "_blank");
  };

  return (
    <div id="show_select" className="package">
      <IoCloseSharp className="IoCloseSharp" onClick={handleClose} />

      <img
        src={toyota_land_cruiser_2023}
        alt="toyota_land_cruiser_2023"
        className="toyota_land_cruiser_2023"
      />

      <div className="show_select">
        <div className="show_select_button">
          <button className="terms_book_now" onClick={handleShowPackageLocal}>
            {t("Packages_1")}
          </button>
          <button className="terms_book_now" onClick={handleShowPackageGcc}>
            {t("Packages_2")}
          </button>
        </div>

        {hideLocal &&
          show_select.map((item, i) => (
            <div key={i}>
              <h2>
                {item.id} D <br />
                {t("Packages_3")}
              </h2>
              <div className="selected_data_all">
                <div className="selected_data data_1">
                  <b>
                    {t("Packages_4")} <br /> {t("Packages_5")}
                  </b>
                  <p>
                    {item.id} <span>{t("Packages_6")}</span>
                  </p>
                </div>

                <div className="selected_data data_2">
                  <b>
                    {t("Packages_7")} <br /> {t("Packages_8")}
                  </b>
                  <p>
                    {item.price_1} <span>{t("Packages_9")} </span>
                    {(item.id == 2 && <span> / {t("Packages_10")}</span>) ||
                      (item.id == 3 && <span> / {t("Packages_11")}</span>)}
                  </p>
                </div>

                <div className="selected_data data_3">
                  <b>
                    {t("Packages_12")} <br /> {t("Packages_13")}
                  </b>
                  <p>
                    {item.price_2} <span>{t("Packages_14")}</span>
                    {(item.id == 2 && <span>/ {t("Packages_15")}</span>) ||
                      (item.id == 3 && <span>/ {t("Packages_16")}</span>)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        {hideLocal && (
          <div className="Package_full_ul">
            <ul>
              <li>{t("Packages_17")}</li>
              <li>{t("Packages_18")}</li>
              <li>{t("Packages_19")}</li>
              <li>7 {t("Packages_20")}</li>
              <li>5 {t("Packages_21")}</li>
            </ul>

            <br />

            <h4>{t("Packages_22")}</h4>
            <ul>
              <li>{t("Packages_23")}</li>
              <li>24 {t("Packages_24")}</li>
              <li>{t("Packages_25")}</li>
            </ul>

            <br />

            <h4>{t("Packages_26")}</h4>
            <ul>
              <li>{t("Packages_27")}</li>
              <li>{t("Packages_28")}</li>
              <li>{t("Packages_29")}</li>
              <li>{t("Packages_30")}</li>
            </ul>
          </div>
        )}
        {/* //////////////GCC/////////////// */}

        {hideGcc && (
          <div>
            <h2>
              3 D 1<br />
              {t("Packages_31")}
            </h2>
            <div className="selected_data_all">
              <div className="selected_data data_1">
                <b>
                  {t("Packages_32")} <br /> {t("Packages_33")}
                </b>
                <p>
                  3 <span>{t("Packages_34")}</span>
                </p>
              </div>

              <div className="selected_data data_2">
                <b>
                  {t("Packages_35")} <br /> {t("Packages_36")}
                </b>
                <p>
                  999 <span>{t("Packages_37")} </span>
                  <span> / {t("Packages_38")}</span>
                </p>
              </div>

              <div className="selected_data data_3">
                <b>
                  {t("Packages_39")} <br /> {t("Packages_40")}
                </b>
                <p>
                  499 <span>{t("Packages_41")}</span>{" "}
                  <span> / {t("Packages_42")}</span>
                </p>
              </div>
            </div>
            <div className="Package_full_ul">
              <ul>
                <li>{t("Packages_43")}</li>
                <li>{t("Packages_44")}</li>
                <li>{t("Packages_45")}</li>
                <li>7 {t("Packages_46")}</li>
                <li>5 {t("Packages_47")}</li>
              </ul>

              <br />

              <h4>{t("Packages_48")}</h4>
              <ul>
                <li>{t("Packages_49")}</li>
                <li>24 {t("Packages_50")}</li>
              </ul>

              <br />

              <h4>{t("Packages_51")}</h4>
              <ul>
                <li>{t("Packages_52")}</li>
                <li>{t("Packages_53")}</li>
                <li>{t("Packages_54")}</li>
                <li>{t("Packages_55")}</li>
                <li>{t("Packages_56")}</li>
              </ul>
            </div>
          </div>
        )}

        {hideLocal ||
          (hideGcc && (
            <button className="terms_book_now" onClick={handleHref}>
              {t("Packages_57")}
            </button>
          ))}
      </div>
    </div>
  );
};

export default Packages;
