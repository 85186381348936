import React from "react";
import "./header.css";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div id="header">
      <div className="header">
        <div className="div_header">
          <h3>{t("header_1")}</h3>
          <h2>{t("header_2")}</h2>
          <h2 className="with">
            {t("header_3")} <br /> <span>{t("header_4")}</span>
          </h2>
          <Link
            className="book_now"
            to="book_car"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            {t("header_5")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
