import React from "react";
import toyota_land_cruiser_2023 from "../../../img/toyota_land_cruiser_2023.webp";
import { IoCloseSharp } from "react-icons/io5";
import "./offer.css";
import { useTranslation } from "react-i18next";

const Offer = ({ setHideOffer }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setHideOffer(false);
  };

  const handleHref = () => {
    window.open("https://api.whatsapp.com/send?phone=97433779585", "_blank");
  };

  return (
    <div id="show_select" className="package">
      <IoCloseSharp className="IoCloseSharp" onClick={handleClose} />

      <img
        src={toyota_land_cruiser_2023}
        alt="toyota_land_cruiser_2023"
        className="toyota_land_cruiser_2023"
      />

      <div className="show_select">
        <div className="offer">
          <div>
            <h2>
              {t("Offer_1")} <br />
              {t("Offer_2")}
            </h2>
            <div className="selected_data_all">
              <div className="selected_data data_1">
                <b>
                  {t("Offer_3")} <br /> {t("Offer_4")}
                </b>
                <p>
                  3 <span>{t("Offer_5")}</span> <span>+ {t("Offer_6")}</span>
                </p>
              </div>

              <div className="selected_data data_2">
                <b>
                  {t("Offer_7")} <br /> {t("Offer_8")}
                </b>
                <p>
                  499 <span>{t("Offer_9")} </span>{" "}
                  <span> / {t("Offer_10")}</span>
                </p>
              </div>
            </div>
            <div className="Package_full_ul">
              <ul>
                <li>{t("Offer_11")}</li>
                <li>{t("Offer_12")}</li>
                <li>{t("Offer_13")}</li>
                <li>7 {t("Offer_14")}</li>
                <li>5 {t("Offer_15")}</li>
              </ul>

              <br />

              <h4>{t("Offer_16")}</h4>
              <ul>
                <li>{t("Offer_17")}</li>
                <li>24 {t("Offer_18")}</li>
                <li>{t("Offer_19")}</li>
              </ul>

              <br />

              <h4>{t("Offer_20")}</h4>
              <ul>
                <li>{t("Offer_21")}</li>
                <li>{t("Offer_22")}</li>
                <li>{t("Offer_23")}</li>
                <li>{t("Offer_24")}</li>
              </ul>
            </div>
          </div>
          {/* //////////////GCC/////////////// */}

          <div>
            <h2>
              {t("Offer_25")}
              <br />
              {t("Offer_26")}
            </h2>
            <div className="selected_data_all">
              <div className="selected_data data_1">
                <b>
                  {t("Offer_27")} <br /> {t("Offer_28")}
                </b>
                <p>
                  3 <span>{t("Offer_29")}</span> <span>+ {t("Offer_30")}</span>
                </p>
              </div>

              <div className="selected_data data_2">
                <b>
                  {t("Offer_31")} <br /> {t("Offer_32")}
                </b>
                <p>
                  999 <span>{t("Offer_33")} </span>
                  <span> / {t("Offer_34")}</span>
                </p>
              </div>
            </div>
            <div className="Package_full_ul">
              <ul>
                <li>{t("Offer_35")}</li>
                <li>{t("Offer_36")}</li>
                <li>{t("Offer_37")}</li>
                <li>7 {t("Offer_38")}</li>
                <li>5 {t("Offer_39")}</li>
              </ul>

              <br />

              <h4>{t("Offer_40")}</h4>
              <ul>
                <li>{t("Offer_41")}</li>
                <li>24 {t("Offer_42")}</li>
              </ul>

              <br />

              <h4>{t("Offer_43")}</h4>
              <ul>
                <li>{t("Offer_44")}</li>
                <li>{t("Offer_45")}</li>
                <li>{t("Offer_46")}</li>
                <li>{t("Offer_47")}</li>
                <li>{t("Offer_48")}</li>
              </ul>
            </div>
          </div>
        </div>

        <button className="terms_book_now" onClick={handleHref}>
          {t("Offer_49")}
        </button>
      </div>
    </div>
  );
};

export default Offer;
